<div class="container">
    <div class="row">
        <div class="col-md-6 center">
            <img src="assets/img/logo-01.png" class="img-fluid down-image" style="margin: 5px;">
            <h4 style="text-align: center;">ALTO PADRÃO EM NEGÓCIOS IMOBILIÁRIOS</h4>
        </div>
        <div class="col-md-6">
            <busca-rapida></busca-rapida>
        </div>
    </div>
</div>
<div *ngIf="datasourceBANNER != []">
    <ngu-carousel #myCarouselBanner [dataSource]="datasourceBANNER" [inputs]="bannerCarouselConfig">
        <ngu-tile *nguCarouselDef="let banner; let i = index">
            <img src="{{banner.banner}}" (click)="openImg(banner.url)" class="img-fluid hover image-banner">
        </ngu-tile>
        <div NguCarouselPrev class="leftRs">
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
        </div>
        <div NguCarouselNext class="rightRs">
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
        </div>
    </ngu-carousel>
</div>
<div class="background">

    <div class="container" style="padding-top: 15px;">
        <h1>
            Imóveis em <strong>Destaque</strong>
        </h1>
        <h4 class="highlighter">Venda</h4>
        <ngu-carousel #myCarousel [dataSource]="datasourceCOMPRA" [inputs]="carouselTile">
            <card-imovel *nguCarouselDef="let item; let i = index" [dataIm]="item" style="padding:10px"></card-imovel>
            <button NguCarouselPrev class="leftRs">
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
            </button>
            <button NguCarouselNext class="rightRs">
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
            </button>
        </ngu-carousel>

        <h4 class="highlighter">Locação</h4>
        <ngu-carousel #myCarousel [dataSource]="datasourceALUGA" [inputs]="carouselTile">
            <card-imovel *nguCarouselDef="let item; let i = index" [dataIm]="item" style="padding:10px"></card-imovel>
            <button NguCarouselPrev class="leftRs">
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
            </button>
            <button NguCarouselNext class="rightRs">
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
            </button>
        </ngu-carousel>
    </div>
</div>