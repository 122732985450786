import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PagesService } from '../pages.service';

@Component({
  selector: 'app-sobre-empresa',
  templateUrl: './sobre-empresa.component.html',
  styleUrls: ['./sobre-empresa.component.scss']
})
export class SobreEmpresaComponent implements OnInit {

  constructor(private service: PagesService, private router: Router) { }
  subs: Subscription
  async ngOnInit() {
    this.subs = this.service.getTextConfig().subscribe((res: any) => {
      if (res[0].TEXT_SOBRE == '' || res[0].TEXT_SOBRE == null) {
        document.getElementById('texto').innerHTML = '<div style="height: 80vh"></div>'
      } else
        document.getElementById('texto').innerHTML = res[0].TEXT_SOBRE + '<div style="height: 40vh"></div>'
    }, err => {
      document.getElementById('texto').innerHTML = '<div style="height: 80vh"></div>'
    })
  }
  g() {
    this.router.navigateByUrl("/adm-login")
  }

  ngOnDestroy(): void {
    if (this.subs) this.subs.unsubscribe()
  }
}
