import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { Subscription } from 'rxjs';
import { Config } from 'src/app/config';
import { PagesService } from '../pages.service';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent {
  // banner slide config
  @ViewChild('myCarouselBanner', { static: false }) myCarousel: NguCarousel<any>;
  loadingData = false
  bannerCarouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    slide: 1,
    speed: 400,
    interval: {
      timing: 5000,
      initialDelay: 1000
    },
    point: {
      visible: true
    },
    load: 2,
    loop: true,
    touch: true
  }
  public carouselTile: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 3, lg: 3, all: 0 },
    slide: 1,
    speed: 400,
    interval: {
      timing: 3000,
      initialDelay: 1000
    },
    point: {
      visible: true
    },
    load: 2,
    loop: true,
    touch: true
  };
  distroyerImoveisIndex: Subscription
  loading = false
  config = new Config()
  datasourceALUGA = []
  datasourceCOMPRA = []
  datasourceBANNER = [];

  defaultImage = this.config.DEFULT_IMAGE
  constructor(private service: PagesService, private router: Router) { }
  async ngOnInit() {
    this.getBanner();
    this.distroyerImoveisIndex = await this.service.imoveisIndex().subscribe(async (response: any) => {
      for (let i = 0; i < 15; i++) {
        if (response.ALUGA[i]) response.ALUGA[i].nome_foto = this.config.URL_FOTOS.concat(response.ALUGA[i].nome_foto)
        if (response.COMPRA[i]) response.COMPRA[i].nome_foto = this.config.URL_FOTOS.concat(response.COMPRA[i].nome_foto)

        if (response.ALUGA[i].suite != "") {
          response.ALUGA[i].quarto = parseInt(response.ALUGA[i].suite) + parseInt(response.ALUGA[i].quarto)
        }

        if (response.COMPRA[i].suite != "") {
          response.COMPRA[i].quarto = parseInt(response.COMPRA[i].suite) + parseInt(response.COMPRA[i].quarto)
        }
      }
      this.datasourceALUGA = response.ALUGA
      this.datasourceCOMPRA = response.COMPRA
      this.loadingData = true
    })
  }

  getBanner() {
    this.service.getBanner().subscribe((response: any) => {
      if (response.response != 'nada') {
        for (let i = 0; i < response.length; i++) {
          response[i].banner = this.config.URL_BANNER.concat(response[i].banner)
        }
        this.datasourceBANNER = response
      }
    })
  }

  openImg(url) {
    window.open(url, '_blank')
  }

  ngOnDestroy(): void {
    if (this.distroyerImoveisIndex) this.distroyerImoveisIndex.unsubscribe();
  }

  detalheImovel(idIm) {
    this.router.navigateByUrl(`/resultado/${idIm}`)
  }

}
