export class Config {
    constructor() { }

    readonly NAME_IMOB = 'Pedrão Imóveis'
    readonly IMOB_LINK_BASE = 'https://www.pedraoimoveis.com.br/'
    // readonly BASE_URL = 'http://localhost/API-IMOB/api/';
    readonly BASE_URL = 'https://waysoft.net.br/apiapp/API-IMOB/api/';
    readonly COD_IMOB: string = '9291f67888d4058261e3d36ff6b12ad5';
    readonly BASE_URL_UPLOAD_BANNER = 'https://www.pedraoimoveis.com.br/banner/upload_banner.php'
    readonly BASE_URL_UPLOAD_CV = 'https://www.pedraoimoveis.com.br/trabalhe_conosco/upload_cv.php'
    readonly CONTACT_URL: string = 'http://waysoft.com.br/API/imob_contatos/';
    readonly VERSION = '2.0.0';
    readonly DEFULT_IMAGE = 'assets/img/defult_img.png'
    readonly WHATSAPP_NUMBER_1 = '(35)99982-0610'
    readonly WHATSAPP_NUMBER_2 = '(35)99982-0605'
    readonly WHATSAPP_NUMBER_3 = ''
    readonly WHATSAPP_NUMBER_4 = ''

    readonly PHONE_NUMBER_1 = '(35)3471-1553'
    readonly PHONE_NUMBER_2 = ''
    readonly PHONE_NUMBER_3 = ''
    readonly PHONE_NUMBER_4 = ''

    readonly URL_LOCADOR = ''
    readonly URL_LOCATARIO = ''
    readonly MANUAL_LOCATARIO = ''
    readonly MANUAL_DESOCUPACAO = ''

    readonly DADOS_EMPRESA = {
        'CIDADE': 'Santa Rita do Sapucaí - MG',
        'BAIRRO': 'Bairro Maristela',
        'CEP': '37540-000',
        'RUA': 'Av. Frederico de Paula Cunha, 229'
    }
    readonly CIDADE_PRINCIPAL = 'SANTA RITA DO SAPUCAÍ'
    readonly URL_FOTOS = 'https://www.pedraoimoveis.com.br/thumb.php?mv=centro&mh=meio&img=fotos/'
    // readonly URL_FOTOS = 'https://www.pedraoimoveis.com.br/fotos/'
    readonly URL_FOTOS_NO_THUMB = 'https://www.pedraoimoveis.com.br/fotos/'
    readonly URL_BANNER = 'https://www.pedraoimoveis.com.br/banner/'

    // readonly EMAIL_1 = 'samuel@brandani.net.br'
    readonly EMAIL_1 = 'administrativo@pedraoimoveis.com.br'
    readonly EMAIL_2 = 'pedrao@pedraoimoveis.com.br'
    readonly EMAIL_3 = ''
    readonly EMAIL_4 = ''
    readonly EMAIL_COLOR = '#df0632'

    readonly INSTAGRAM = 'https://www.instagram.com/pedrao_imoveis/';
    readonly FACEBOOK = 'https://www.facebook.com/imobiliariapedraoimoveis';
    readonly YOUTUBE = 'https://www.youtube.com/channel/UCW4mCqNg9fmX82YUyAaqLiA';
    readonly LINKEDIN = 'https://www.linkedin.com/in/pedr%C3%A3o-im%C3%B3veis-79ab011a5/';
}


