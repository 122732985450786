<br>
<div class="container">
    <div class="row">
        <div class="col">
            <h2>Sobre a empresa</h2>
        </div>
        <div class="col-auto">
            <button mat-raised-button color="primary" (click)="g()"
                routerLinkActive="router-link-active">Administrativo</button>
        </div>
    </div>
    <div id="texto">
    </div>
</div>