<div class="footer">
    <br>
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <h4>Contatos</h4>
                <h5 *ngIf='WHATSAPP_NUMBER_1 != ""' class="rede-social" (click)="openWpp(WHATSAPP_NUMBER_1)">
                    {{WHATSAPP_NUMBER_1}}
                </h5>
                <h5 *ngIf='WHATSAPP_NUMBER_2 != ""' class="rede-social" (click)="openWpp(WHATSAPP_NUMBER_2)">
                    {{WHATSAPP_NUMBER_2}}
                </h5>
                <h5 *ngIf='PHONE_NUMBER_1 != ""'>
                    {{PHONE_NUMBER_1}}
                </h5>
                <h5 *ngIf='EMAIL != ""'>
                    {{EMAIL}}
                </h5>
            </div>
            <div class="col-md-4">
                <h4>Visite nossas redes sociais!</h4>
                <div class="col-auto rede-social" *ngIf='face != ""' (click)="open(face)">
                    <img src="assets/icons/facebook.png" class="img-fluid" alt="facebook-icon" style="max-width:40px ;">
                    Facebook
                </div>
                <div class="col-auto rede-social" *ngIf="insta != ''" (click)="open(insta)">
                    <img src="assets/icons/instagram.png" class="img-fluid" alt="instagram-icon"
                        style="max-width:40px ;">
                    Instagram
                </div>
                <div class="col-auto rede-social" *ngIf="insta != ''" (click)="open(yt)">
                    <img src="assets/icons/yt.png" class="img-fluid" alt="instagram-icon" style="max-width:40px ;">
                    YouTube
                </div>
                <div class="col-auto rede-social" *ngIf="insta != ''" (click)="open(linkdin)">
                    <img src="assets/icons/linkedin.png" class="img-fluid" alt="instagram-icon"
                        style="max-width:40px ;">
                    Linkedin
                </div>
            </div>
            <div class="col-md-4">
                <h4>Endereço</h4>
                <h5>{{DADOS_EMPRESA.RUA}}, {{DADOS_EMPRESA.BAIRRO}}</h5>
                <h5>{{DADOS_EMPRESA.CIDADE}}</h5>
                <h5>{{DADOS_EMPRESA.CEP}}</h5>
            </div>
        </div>

    </div>
    <div style="text-align: center;">
        <span (click)="open('https://pedraoimoveis.com.br/politicaPrivacidade/POLITICAPRIVACIDADEPEDRAO.pdf')"
            class="rede-social">
            Política de privacidade
        </span><br>
        <span (click)="open('https://waysoft.net.br/')" class="rede-social">
            Desenvolvido por WaySoft
        </span>
    </div>
</div>