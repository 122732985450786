import { Component, OnInit } from '@angular/core';
import { Config } from 'src/app/config';

@Component({
  selector: 'app-fale-conosco',
  templateUrl: './fale-conosco.component.html',
  styleUrls: ['./fale-conosco.component.scss']
})
export class FaleConoscoComponent implements OnInit {

  constructor() { }
  config = new Config();
  ngOnInit() {
  }
  open(number) {
    window.open(`https://api.whatsapp.com/send?1=pt_BR&phone=55${number}`, '_blank')
  }
}
